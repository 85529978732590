import { withAuthenticationRequired } from '@auth0/auth0-react';


export default function withRoleRequired(Component) {
  return withAuthenticationRequired(Component, {
    claimCheck: (user) => {
      const hasFWRole = user && (user['https://future-workforce.demo.alephinsights.com/roles']?.includes('access-content'));
      return hasFWRole;
    },
    returnTo: '/'
  });
}
