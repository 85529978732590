import React from "react";
import Loadable from "react-loadable";
import withRoleRequired from '../../lib/withRoleRequired';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Spinner from "../../components/deliberative-survey/Spinner";

const LoadableHighlevelFindings = Loadable({
  loader: () => import("../../components/deliberative-survey/HighlevelFindings"),
  loading() {
    return (
      <>
        <div className='m-auto'>
          <Spinner/>
        </div>
      </>
    );
  }
});


const Highlevel: React.FunctionComponent = () => (
  <Layout pageHeader="High Level Findings"
    breadcrumbs={[{ label: 'Attitudes Towards Defence', link: '/deliberative-survey' }]}
    moreInfo = "deliberative-survey/high-level-findings"
  >
    <SEO
      keywords={[`future`, `workforce`, `modelling`, `tool`]}
      title="Attitudes Towards Defence High Level Findings"
    />
    <div className = "max-w-6xl px-4 py-4 mx-auto md:px-8 md:py-4 h-full">
      <div className='flex flex-col items-stretch h-full '>
        <div className = "pb-4">
          The high level findings are presented in textual form below. They are arranged under Understanding of Defence (what participants already knew about Defence), Careers Information (what level of career advice was being provided) and Propensity to Work in Defence (which factors contributed to a willingness to work for Defence).
        </div>
        <LoadableHighlevelFindings/>
      </div>
    </div>

  </Layout>
)

export default withRoleRequired(Highlevel);
